import React from 'react';
import { useNavigate } from 'react-router-dom';

const ChatBodyAdmin = ({ messages, typingStatus, lastMessageRef, selectedUser }) => {
  const navigate = useNavigate();

  const handleLeaveChat = () => {
    localStorage.removeItem('userId');
    navigate('/');
    window.location.reload();
  };

  return (
    <>
      <header className="chat__mainHeader">
        <p>💬 Chat with {selectedUser}</p> {/* Display selected user's name or ID */}
        <button className="leaveChat__btn" onClick={handleLeaveChat}>
          Leave Chat
        </button>
      </header>

      <div className="message__container">
        {messages.map((message) => (
          message.senderId === localStorage.getItem('userId') ? (
            <div className="message__chats message__chats--sender" key={message.id}>
              <p className="chat__name">You</p>
              <div className="chat__bubble chat__bubble--sender">
                <p>{message.text}</p>
              </div>
            </div>
          ) : (
            <div className="message__chats message__chats--recipient" key={message.id}>
              <p className="chat__name">{message.name}</p>
              <div className="chat__bubble chat__bubble--recipient">
                <p>{message.text}</p>
              </div>
            </div>
          )
        ))}

        <div className="message__status">
          {typingStatus && <p>{typingStatus}</p>}
        </div>

        <div ref={lastMessageRef} />
      </div>
    </>
  );
};

export default ChatBodyAdmin;
