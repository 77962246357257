import { configureStore, createSlice } from '@reduxjs/toolkit';

// Example slice
const initialState = { value: 0 };
const counterSlice = createSlice ({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
        console.log(state,'state')
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
  },
});

export const { increment, decrement } = counterSlice.actions;

const store = configureStore({
  reducer: {
    counter: counterSlice.reducer,
  },
});

export default store;
