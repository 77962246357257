import React, { useState, useEffect } from "react";

const ChatBarAdmin = ({ socket, setSelectedUser,userAll }) => {
  const [users, setUsers] = useState(userAll);
  const [Loading, setLoading] = useState(true);

  const [notifications, setNotifications] = useState([]); // Notifications state
  const [showNotifications, setShowNotifications] = useState(false); // Popup toggle state
  useEffect(() => {
    console.log(users,'users')
      // Fetch messages between the logged-in user and the selected user
      fetch(`https://chat.al-bassif.com/api/Notification/${localStorage.getItem('userId')}`)
        .then(response => response.json())
        .then(data =>{
            setNotifications(data);

        })
        .catch(error => console.error('Error fetching messages:', error));
    
  }, []);  // Re-fetch when selectedUser or userId changes

  useEffect(() => {
    // Listen for user updates from the server
    socket.on("newUserResponseAdmin", (data) => {
      setUsers(data.usersWithMessages);
      setLoading(false)
      console.log(data,'newUserResponseAdmin');
    });


    // Listen for notifications from the server
    socket.on("notification", (notification) => {
      setNotifications((prevNotifications) => [...prevNotifications, notification]);
    });

    const fetchToken = () => {
      const token = localStorage.getItem("authToken");
      if (token) {
        socket.emit("UserResponseadmin", token);
      }
    };

    fetchToken();

    return () => {
      socket.off("notification");
      socket.off("newUserResponseAdmin");

    };
  }, [socket]);

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  return (
    <div className="chat-sidebar">
      <h2>Open Chat</h2>
      <div className="notification-menu">
        <button className="notification-bell" onClick={toggleNotifications}>
          🛎️ Notifications {notifications.length > 0 && <span>({notifications.length})</span>}
        </button>
        {showNotifications && (
          <div className="notification-popup">
            <h4>Notifications</h4>
            {notifications.length === 0 ? (
              <p className="no-notifications">No notifications</p>
            ) : (
              notifications.map((notification, index) => (
                <div key={index} className="notification-item"   
                     onClick={() => handleUserClick(notification.title)}
                    style={{backgroundColor:notification.status==="read "?'white':'gary'}}>
                  <h5>{notification.title}</h5>
                  <p>{notification.message}</p>
                  <span>{new Date(notification.timestamp).toLocaleString()}</span>
                </div>
              ))
            )}
          </div>
        )}
      </div>

      <div>
        <h4 className="chat-header">Active Users</h4>
        <div className="chat-users">
          {users.length === 0 ? (
            <p className="no-users">No active users</p>
          ) :  <>
          {Loading ?   <p className="no-users">Loading User .... </p> :    userAll?.map((user) => (
              <div
                key={user.user?.userId}
                className="user-card"
                onClick={() => handleUserClick(user.user?.userId)}
              >
                <div className="user-avatar">
                  <img
                    src={`https://robohash.org/${user.user?.userId}.png?size=50x50`}
                    alt={user.user?.userId}
                  />
                </div>
                <div className="user-info">
                <p className="user-name">{user.user?.name ?user.user?.name:user.user?.userId}</p>
                  <p className="user-name">{user.user?.userId}</p>
                  <p className="user-role" style={{background: user.status=="0" && user.senderId==localStorage.getItem("userId")?'gary':'green',color:user.status=="0" && user.senderId==localStorage.getItem("userId")?'black':'white'}}> {user.lastMessage?.substring(0, 20)}</p>
                </div>
              </div>
            ))       }
          
           </> 
          
          }
        </div>
      </div>

      {/* Notification Bell */}
 
      {/* Social Media Links */}
      <div className="social-links">
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
          Facebook
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          Twitter
        </a>
        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
          LinkedIn
        </a>
      </div>
    </div>
  );
};

export default ChatBarAdmin;
