import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import ChatPage from './components/ChatPage';
import ChatPageAdmin from './components/admin/ChatPageAdmin';
import Test from './components/test';

function App() {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/chat" element={<ChatPage />} />
        <Route path="/test" element={<Test />} />

        <Route path="/ChatPageAdmin" element={<ChatPageAdmin />} />
        {/* Catch-all route for 404 */}
    </Routes>
  );
}

export default App;
