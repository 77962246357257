import React, { useEffect, useState, useRef } from 'react';
import ChatBar from './ChatBar';  // Correct path
import ChatBody from './ChatBody';  // Correct path
import ChatFooter from './ChatFooter';  // Correct path
import { socket } from '../socket.js';

const ChatPage = () => {
  const [messages, setMessages] = useState([]);
  const [typingStatus, setTypingStatus] = useState('');
  const [user, setUser] = useState('');

  const lastMessageRef = useRef(null);
  useEffect(() => {
    // Listen for new user data from the server and update users state
    socket.on('newUserResponse', (data) => {
      setUser(data?.user);
    });
  }, [socket]);
  useEffect(() => {
    const token = localStorage.getItem('authToken'); // Get token from localStorage
console.log(token,'token')
    if (token) {
      socket.emit('updateSocketio', token);
    }
  }, [])


  useEffect(() => {
    socket.on('messageResponse', (data) => setMessages([...messages, data]));

  }, [socket, messages]);
  useEffect(() => {
    socket.on('typingResponse', (data) => setTypingStatus(data));
  }, [socket]);
  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);
  const handleSendMessage = (messageText) => {
    if (user) {
      const message = {
        text: messageText, 
        senderId: localStorage.getItem('userId'),
        recipientSocketID: user.userId,
         // Send to selected user's socketID
      };
      socket.emit('message', message);  // Send message to the server

      // Update messages array with the new message (preserve previous messages)
      setMessages((prevMessages) => [...prevMessages,message]);
    }

  };
  useEffect(() => {
    if (user) {
      // Fetch messages between the logged-in user and the selected user
      fetch(`https://chat.al-bassif.com/api/messages/${localStorage.getItem('userId')}/${user.userId}`)
        .then(response => response.json())
        .then(data =>{
            const ma=[];
            data.map(value=>{
                ma.push({id:value._id,senderId:value.senderId,text:value.message})
            })
            setMessages(ma)
            console.log({data})
        })
        .catch(error => console.error('Error fetching messages:', error));
    }
  }, [user, localStorage.getItem('userId')]); 
  return (
    <div className="chat">
      <ChatBar socket={socket} />
      <div className="chat__main">
        <ChatBody
          messages={messages}
          typingStatus={typingStatus}
          lastMessageRef={lastMessageRef}
        />
        <ChatFooter socket={socket} handleSendMessages={handleSendMessage} />
      </div>
    </div>
  );

};

export default ChatPage;
